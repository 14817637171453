import { observer } from "mobx-react-lite";
import { useStores } from "../../../store/RootStoreContext";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { ApiUrls } from "../../../queries";
import { urlReset } from "../../../utils/utils";
import { CapsuleTabs } from "antd-mobile";
import SettingsPage from "./SettingsPage";
import TrainingPage from "./TrainingPage";

const Training = observer(() => {
  const { ID } = useParams();
  const { trainingStore, userStore } = useStores();
  const { user } = userStore;
  const { getTraining, training, exercises, loading } = trainingStore;
  useEffect(() => {
    if (ID) void getTraining(ID);
  }, [ID, getTraining]);
  /* Восстанавливает url, если тренировка запущена, но пытаются сменить ID */
  useEffect(() => {
    if (training?.ID && training?.ID !== ID)
      urlReset(`/${ApiUrls.trainings}/${training?.ID}`);
  }, [ID, training?.ID]);

  const [close, setClose] = useState(false);
  if (close) return <Navigate to={`/${ApiUrls.trainings}`} />;
  return loading ? (
    <Loader />
  ) : exercises?.length ? (
    <div className="train">
      {user?.appSettings?.view?.training?.settingsHide ? (
        <TrainingPage setClose={setClose} />
      ) : (
        <CapsuleTabs>
          <CapsuleTabs.Tab title="Тренировка" key="train">
            <TrainingPage setClose={setClose} />
          </CapsuleTabs.Tab>
          <CapsuleTabs.Tab title="Настройка" key="settings">
            <SettingsPage />
          </CapsuleTabs.Tab>
        </CapsuleTabs>
      )}
    </div>
  ) : (
    <div />
  );
});

export default Training;
