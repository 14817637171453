import { observer } from "mobx-react-lite";
import { ILoginData } from "../../store/userStore";
import { useStores } from "../../store/RootStoreContext";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, Card, Form, FormItemProps, Input } from "antd-mobile";
import { Paths } from "../Navigation";

const Login = observer(() => {
  const {
    userStore: { login, isAuth, loading },
  } = useStores();

  const checkUserCredentials = (event: React.FormEvent) => {
    event.preventDefault();
    if (!loginData.identifier) {
      setMailHelp({
        help: "Введите email",
        hasFeedback: true,
      });
    } else if (mailHelp.hasFeedback) setMailHelp({});
    if (!loginData.password) {
      setPassHelp({
        help: "Введите пароль",
        hasFeedback: true,
      });
    } else if (passHelp.hasFeedback) setPassHelp({});

    if (mailHelp.hasFeedback || passHelp.hasFeedback) return;
    login(loginData).catch(() => {
      setPassHelp({
        help: "Введите правильный пароль",
        hasFeedback: true,
      });
    });
  };
  const [mailHelp, setMailHelp] = useState<FormItemProps>({});
  const [passHelp, setPassHelp] = useState<FormItemProps>({});
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState<ILoginData>({
    password: "",
    identifier: "",
  });
  if (isAuth) return <Navigate to={`/${Paths.profile}`} replace />;
  return (
    <Card style={{ margin: 12 }}>
      <Form
        layout="vertical"
        footer={
          <>
            <Button
              onClick={checkUserCredentials}
              color="primary"
              style={{ width: "100%", marginTop: 10 }}
              loading={loading}
            >
              Войти
            </Button>
            <Button
              onClick={() => {
                navigate(`/${Paths.register}`);
              }}
              style={{ width: "100%", marginTop: 10 }}
              loading={loading}
            >
              Зарегистрироваться
            </Button>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              color="default"
              style={{ width: "100%", marginTop: 10 }}
            >
              Назад
            </Button>
          </>
        }
      >
        <Form.Header>Авторизация</Form.Header>
        <Form.Item required label="E-mail" {...mailHelp}>
          <Input
            type="email"
            placeholder="Введите e-mail"
            value={loginData.identifier}
            onChange={(identifier) => {
              setLoginData({ ...loginData, identifier });
            }}
          />
        </Form.Item>
        <Form.Item required label="Пароль" {...passHelp}>
          <Input
            type="password"
            value={loginData.password}
            onChange={(password) => {
              setLoginData({ ...loginData, password });
            }}
            placeholder="Введите пароль"
          />
        </Form.Item>
      </Form>
    </Card>
  );
});
export default Login;
