import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { isNumber, noPhotoUrl } from "../../../utils/utils";
import { useStores } from "../../../store/RootStoreContext";
import { Paths } from "../../Navigation";
import { Card, Collapse, Divider, Image, List } from "antd-mobile";

const Subdivision = observer(() => {
  const { ID } = useParams();
  const {
    subdivisionStore: { subdivision, loading, getSubdivision },
  } = useStores();
  useEffect(() => {
    if (isNumber(ID)) void getSubdivision(ID);
  }, [ID, getSubdivision]);
  const navigate = useNavigate();
  return (
    <div>
      {loading ? (
        <Loader />
      ) : subdivision ? (
        <Card className="library-single-content">
          <Image src={noPhotoUrl} />
          <Divider contentPosition="left">Название</Divider>
          <Card>{subdivision.name}</Card>
          <Divider contentPosition="left">Группа мышц</Divider>
          <Card
            onClick={() =>
              navigate(
                `/${Paths.library}/${Paths.muscles}/${subdivision.muscle.id}`,
              )
            }
          >
            {subdivision.muscle.name}
          </Card>
          <Divider contentPosition="left">Описание</Divider>
          <Card>{subdivision.description}</Card>
          {!!subdivision.workload_subdivisions.length && (
            <Collapse>
              <Collapse.Panel key={subdivision.name} title={"Упражнения"}>
                <List>
                  {subdivision.workload_subdivisions
                    .filter((wl) => wl.exercise)
                    .map((el) => (
                      <List.Item
                        onClick={() =>
                          navigate(
                            `/${Paths.library}/${Paths.exercises}/${el.exercise.id}`,
                          )
                        }
                        prefix={
                          <Image
                            src={el.exercise.photo?.url || noPhotoUrl}
                            style={{ borderRadius: 20 }}
                            fit="cover"
                            width={40}
                            height={40}
                          />
                        }
                        key={el.exercise.id}
                      >
                        {el.exercise.name}
                      </List.Item>
                    ))}
                </List>
              </Collapse.Panel>
            </Collapse>
          )}
        </Card>
      ) : (
        <div />
      )}
    </div>
  );
});
export default Subdivision;
