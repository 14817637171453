import { observer } from "mobx-react-lite";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import FooterMenu from "./FooterMenu";
import { useEffect } from "react";
import { Paths } from "./Navigation";

const Main = observer(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/") navigate(Paths.helloPage);
  }, []);
  return (
    <>
      <div className="content">
        <Outlet />
      </div>
      <FooterMenu />
    </>
  );
});
// export default authorized(Main);
export default Main;
