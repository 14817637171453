import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Loader from "../../../components/loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../store/RootStoreContext";
import { Image, List } from "antd-mobile";
import { noPhotoUrl } from "../../../utils/utils";
/*todo: сделать IndexBar */
const Muscles = observer(() => {
  const { page } = useParams();
  const { muscleStore } = useStores();
  const { loading, muscles, getMuscles } = muscleStore;
  useEffect(() => {
    if (!muscles?.length) void getMuscles();
  }, [page]);
  const navigate = useNavigate();
  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : (
          !!muscles?.length && (
            <List>
              {muscles.map((el) => (
                <List.Item
                  key={el.id}
                  onClick={() => navigate(el.id.toString())}
                  description={el.description}
                  prefix={
                    <Image
                      src={el.photo?.url || noPhotoUrl}
                      style={{ borderRadius: 20 }}
                      fit="cover"
                      width={40}
                      height={40}
                    />
                  }
                >
                  {el.name}
                </List.Item>
              ))}
            </List>
          )
        )}
      </div>
    </>
  );
});

export default Muscles;
