import { makeAutoObservable } from "mobx";
import { ApiUrls } from "../queries";
import axios from "axios";
import { ISubdivision } from "@strapiTypes/generated/ISubdivision";
import { Payload } from "@strapiTypes/generated/Payload";
import qs from "qs";

class SubdivisionStore {
  subdivisionsPayload: Payload<ISubdivision[]>;
  subdivision: ISubdivision;
  loading: boolean;
  constructor() {
    makeAutoObservable(this);
  }

  getSubdivisions = async (page?: number, pageSize?: number) => {
    pageSize ??= +process.env.REACT_APP_PAGINATION;
    try {
      this.setLoading(true);
      const objQ = {
        populate: "*",
      };
      if (page) objQ["pagination"] = { pageSize, page };
      const res = await axios.get<Payload<ISubdivision[]>>(
        `${ApiUrls.subdivisions}?${qs.stringify(objQ)}`,
      );
      if (res.status === 200 && res.data) this.setSubdivisions(res.data);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
  setSubdivision = (subdivision: ISubdivision) => {
    this.subdivision = subdivision;
  };
  setSubdivisions = (subdivisions: Payload<ISubdivision[]>) => {
    this.subdivisionsPayload = subdivisions;
  };
  getSubdivision = async (ID: number | string) => {
    try {
      this.setLoading(true);
      const res = await axios.get<Payload<ISubdivision>>(
        `${ApiUrls.subdivisionsLib}/${ID}`,
      );
      if (res.status === 200 && res.data) this.setSubdivision(res.data.data);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
}

const subdivisionStore = new SubdivisionStore();
export type TSubdivisionStore = SubdivisionStore;
export { subdivisionStore };
