import { createBrowserRouter, RouteObject } from "react-router-dom";
import Login from "./auth/Login";
import Muscles from "./library/muscles/Muscles";
import SignUp from "./auth/SignUp";
import Subdivisions from "./library/subdivisions/Subdivisions";
import Exercises from "./library/exercises/Exercises";
import NotFoundPage from "../components/notfoundpage/NotFoundPage";
import Main from "./Main";
import Library from "./library/Library";
import React from "react";
import Profile from "./profile/Profile";
import Trainings from "./trainings/Trainings";
import Training from "./trainings/training/Training";
import Exercise from "./library/exercises/Exercise";
import Subdivision from "./library/subdivisions/Subdivision";
import Muscle from "./library/muscles/Muscle";
import HelloPage from "./HelloPage";
import Feedback from "./feedback/Feedback";

export enum Paths {
  library = "library",
  subdivisions = "subdivisions",
  muscles = "muscles",
  exercises = "exercises",
  trainings = "trainings",
  training = "training",
  trainExercises = "train-exercises",
  profile = "profile",
  login = "login",
  register = "register",
  page = "page",
  helloPage = "helloPage",
  feedback = "feedback",
}
const pagination = `${Paths.page}/:${Paths.page}`;
const muscles: RouteObject[] = [
  {
    path: `${Paths.muscles}`,
    element: <Muscles />,
    children: [{ path: pagination, element: <Muscles /> }],
  },
  {
    path: `${Paths.muscles}/:ID`,
    element: <Muscle />,
  },
];
const feedBack: RouteObject[] = [
  {
    path: `/${Paths.feedback}`,
    element: <Feedback />,
    errorElement: <NotFoundPage />,
  },
];
const subdivisions: RouteObject[] = [
  {
    path: `${Paths.subdivisions}`,
    element: <Subdivisions />,
    children: [
      {
        path: pagination,
        element: <Subdivisions />,
      },
    ],
  },
  {
    path: `${Paths.subdivisions}/:ID`,
    element: <Subdivision />,
  },
];
const exercises: RouteObject[] = [
  {
    path: `${Paths.exercises}`,
    element: <Exercises />,
    children: [
      {
        path: pagination,
        element: <Exercises />,
      },
    ],
  },
  {
    path: `${Paths.exercises}/:ID`,
    element: <Exercise />,
  },
];
const library: RouteObject[] = [
  {
    path: Paths.library,
    element: <Library />,
    children: [].concat(muscles, subdivisions, exercises),
  },
];

const helloPage: RouteObject[] = [
  {
    path: Paths.helloPage,
    element: <HelloPage />,
  },
];
const trainings: RouteObject[] = [
  {
    path: `/${Paths.trainings}`,
    element: <Trainings />,
  },
  {
    path: `/${Paths.trainings}/:ID`,
    element: <Training />,
  },
  {
    path: `/${Paths.training}`,
    element: <Training />,
  },
];
const profile: RouteObject[] = [
  {
    path: `/${Paths.profile}`,
    element: <Profile />,
  },
];
const routes: RouteObject[] = [
  {
    path: `/${Paths.login}`,
    element: <Login />,
  },
  {
    path: `/${Paths.register}`,
    element: <SignUp />,
  },
  {
    path: "/",
    element: <Main />,
    errorElement: <NotFoundPage />,
    children: [].concat(helloPage, library, trainings, profile, feedBack),
  },
];

export const router = createBrowserRouter(routes);
