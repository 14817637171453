import { RouterProvider } from "react-router-dom";
import { router } from "./forms/Navigation";
import { RootStoreContext } from "./store/RootStoreContext";
import RootStore from "./store/rootStore";
// import { ConfigProvider } from "antd";
import React from "react";
// import { ThemeConfig } from "antd/lib";
import "antd-mobile/bundle/css-vars-patch.css";
import "./utils/utils";

function App() {
  /*todo: сделать изменение тема (тёмная / белая)*/
  /*const [theme] = useState<ThemeConfig>({
    components: {
      Select: {
        selectorBg: colorPalette.main,
        colorText: colorPalette.text,
        colorPrimaryText: colorPalette.accent,
      },
    },
    token: {
      borderRadius: 8,
      colorBgBase: colorPalette.main,
      colorTextBase: colorPalette.text,
      boxShadowTertiary: "none",

      colorPrimary: colorPalette.accent,
      colorPrimaryBg: colorPalette.main,

      colorInfo: colorPalette.text,

      colorTextDescription: colorPalette.error,
      colorError: colorPalette.error,
      colorErrorBorder: colorPalette.error,

      colorBorder: colorPalette.main,
      colorTextPlaceholder: colorPalette.text,
    },
  });*/
  return (
    <React.StrictMode>
      <RootStoreContext.Provider value={new RootStore()}>
        {/*<ConfigProvider theme={theme}>*/}
        <RouterProvider router={router} />
        {/*</ConfigProvider>*/}
      </RootStoreContext.Provider>
    </React.StrictMode>
  );
}

export default App;
