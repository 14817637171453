import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Loader from "../../../components/loader/Loader";
import { exerciseStore } from "../../../store/exerciseStore";
import { DotLoading, Image, InfiniteScroll, List } from "antd-mobile";
import { IExercise } from "@strapiTypes/generated/IExercise";
import { Payload } from "@strapiTypes/generated/Payload";
import { useNavigate } from "react-router-dom";
import { noPhotoUrl } from "../../../utils/utils";

const InfiniteScrollContent = ({
  hasMore,
  quant,
}: {
  quant?: number;
  hasMore?: boolean;
}) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Загрузка</span>
          <DotLoading />
        </>
      ) : (
        <span>
          {quant} / {quant} Упражнений
        </span>
      )}
    </>
  );
};
/*todo: сделать IndexBar ?? тогда надо будет выкачивать все Exercises*/
const Exercises = observer(() => {
  const { getExercisesForLib } = exerciseStore;
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [exers, setExers] = useState<Partial<Payload<IExercise[]>>>({});
  useEffect(() => {
    getExercisesForLib(1).then((el) => {
      setExers(el);
    });
    setLoad(false);
  }, []);
  const [hasMore, setHasMore] = useState(true);
  const loadMore = async () => {
    if (exers?.meta) {
      getExercisesForLib(exers.meta.pagination.page + 1).then((el) => {
        setExers({
          ...el,
          data: [...exers.data, ...el.data],
        });
      });
      setHasMore(exers.meta.pagination.page < exers.meta.pagination.pageCount);
    }
  };

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        !!exers?.data?.length && (
          <List>
            {exers?.data.map?.((el) => (
              <List.Item
                key={el.id}
                onClick={() => navigate(el.id.toString())}
                prefix={
                  <Image
                    src={el.photo?.url || noPhotoUrl}
                    style={{ borderRadius: 20 }}
                    fit="cover"
                    width={40}
                    height={40}
                  />
                }
              >
                {el.name}
              </List.Item>
            ))}
          </List>
        )
      )}
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        <InfiniteScrollContent
          hasMore={hasMore}
          quant={exers?.meta?.pagination?.total}
        />
      </InfiniteScroll>
    </>
  );
});
export default Exercises;
