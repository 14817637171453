export const colorPalette = {
  main: "var(--main)",
  back: "var(--back)",
  text: "var(--text)",
  accent: "var(--accent)",
  error: "var(--error)",
};
export const isNumber = (ID: string | number) => {
  return !!(ID && !isNaN(+ID));
};
export const urlReset = (url: string) => {
  window.history.replaceState(null, undefined, url);
};
type KeyType = string | number;
export function dictify<T extends object = any>(
  array: T[],
  key: KeyType | ((value: T, i: number, array: T[]) => KeyType),
): Record<KeyType, T> {
  const dict: Record<KeyType, T> = {};
  for (let i = 0; i < array.length; i++) {
    const value = array[i];
    const k = typeof key === "function" ? key(value, i, array) : value[key];
    dict[k] = value;
  }
  return dict;
}

/**
 * Группирует объекты по признакам, возвращаемым grFn
 * @param objs массив объектов для группировки
 * @param grFn функция, возвращающая массив названий групп для объектов. Например, ["группа1", "группа2"]
 * @returns возвращает объект вида { группа1: { группа2: [объекты] } }
 */
export function groupBy<T extends object = any>(
  objs: T[],
  grFn: (obj: T) => (string | number)[],
): object {
  const r: object = {};
  if (!objs?.length) return;
  objs.forEach((o) => {
    const keys = grFn(o);
    const last = keys.pop();
    let res = r;
    for (const key of keys) {
      if (!res[key]) res[key] = {};
      res = res[key];
    }
    if (!res[last]) res[last] = [];
    res[last].push(o);
  });
  return r;
}

export const noPhotoUrl = window.location.origin + "/noPhoto.jpg";
export const noPhotoUser = window.location.origin + "/noPhotoUser.jpg";

export function getRandomIntNum(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}
/*export async function confirmTxt(content: string) {
  return await Dialog.confirm({
    content,
    confirmText: "Да",
    cancelText: "Нет",
  });
}*/
