import { Dialog, List, SwipeAction, Toast } from "antd-mobile";
import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../store/RootStoreContext";
import { Action } from "antd-mobile/es/components/swipe-action";
import { IExercise } from "@strapiTypes/generated/IExercise";

const SettingsPage = observer(() => {
  const { trainingStore } = useStores();
  const { exercises, deleteExerciseFromTraining } = trainingStore;

  const changeExercise = useCallback(async (_: IExercise) => {
    await Dialog.alert({
      content: "Пока что не работает",
      confirmText: "Хорошо",
    });
    /*await Dialog.confirm({
      content: "Вы уверены, что хотите заменить упражнение?",
      confirmText: "Да",
      cancelText: "Нет",
    });*/
  }, []);
  const deleteExercise = useCallback(async (item: IExercise) => {
    const res = await Dialog.confirm({
      content: "Вы уверены, что хотите удалить упражнение из тренировки?",
      confirmText: "Да",
      cancelText: "Нет",
    });
    if (res && !deleteExerciseFromTraining(item.id)) {
      Toast.show("Больше нельзя удалить");
    }
  }, []);
  const rightActions: Action[] = [
    {
      key: "delete",
      text: "Удалить",
      color: "danger",
    },
  ];
  const leftActions: Action[] = [
    {
      key: "change",
      text: "Поменять",
      color: "warning",
    },
  ];
  const onAction = (action: Action, item: IExercise) => {
    const actionMap = {
      delete: deleteExercise,
      change: changeExercise,
    };
    actionMap[action.key]?.(item);
  };
  return (
    <List>
      {exercises?.map((item) => (
        <SwipeAction
          key={item.id}
          onAction={(action) => {
            onAction(action, item);
          }}
          leftActions={leftActions}
          rightActions={rightActions}
        >
          <List.Item
            onClick={() => {
              Toast.show("Смахните влево для замены и вправо для удаления");
            }}
          >
            {item.name}
          </List.Item>
        </SwipeAction>
      ))}
    </List>
  );
});

export default SettingsPage;
