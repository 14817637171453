import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { subdivisionStore } from "../../../store/subdivisionStore";
import { Image, List } from "antd-mobile";
import { groupBy, noPhotoUrl } from "../../../utils/utils";
import Loader from "../../../components/loader/Loader";
import { ISubdivision } from "@strapiTypes/generated/ISubdivision";
import { useNavigate } from "react-router-dom";

const Subdivisions = observer(() => {
  const { loading, subdivisionsPayload, getSubdivisions } = subdivisionStore;
  const navigate = useNavigate();
  useEffect(() => {
    if (!subdivisionsPayload?.data?.length) void getSubdivisions(1, 100);
  }, [getSubdivisions]);
  const grouped = groupBy(subdivisionsPayload?.data, (el) => [el.muscle.name]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : subdivisionsPayload?.data?.length ? (
        Object.entries(grouped).map(([muscName, val]) => (
          <List key={muscName} header={muscName}>
            {Object.values(val).map((el: ISubdivision) => {
              return (
                <List.Item
                  key={el.id}
                  onClick={() => navigate(el.id.toString())}
                  prefix={
                    <Image
                      src={el.photo?.url || noPhotoUrl}
                      style={{ borderRadius: 20 }}
                      fit="cover"
                      width={40}
                      height={40}
                    />
                  }
                >
                  {el.name}
                </List.Item>
              );
            })}
          </List>
        ))
      ) : (
        <div />
      )}
    </>
  );
});

export default Subdivisions;
