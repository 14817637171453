import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React from "react";
import { Paths } from "./Navigation";
import {
  SmileOutline,
  UnorderedListOutline,
  UserOutline,
} from "antd-mobile-icons";
import { TabBar } from "antd-mobile";

const FooterMenu = observer(() => {
  const { pathname } = useLocation();

  const path = `/${pathname.split("/")[1]}`;
  const navigate = useNavigate();
  const tabs = [
    {
      key: `/${Paths.library}`,
      title: "Библиотека",
      icon: <UnorderedListOutline />,
    },
    {
      key: `/${Paths.trainings}`,
      title: "Тренировка",
      icon: <SmileOutline />,
    },
    {
      key: `/${Paths.profile}`,
      title: "Профиль",
      icon: <UserOutline />,
    },
  ];
  return (
    <nav className="menu">
      <TabBar activeKey={path} onChange={navigate}>
        {tabs.map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </nav>
  );
});

export default FooterMenu;
