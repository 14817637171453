import { observer } from "mobx-react-lite";
import { Button, Steps, Swiper } from "antd-mobile";
import { Step } from "antd-mobile/es/components/steps/step";
import { CheckCircleFill, ClockCircleFill } from "antd-mobile-icons";
import React, { useState } from "react";
import Exercise from "./Exercise";
import { useStores } from "../../../store/RootStoreContext";

interface ITrainPageProps {
  setClose: (close: boolean) => void;
}

const TrainingPage = observer(({ setClose }: ITrainPageProps) => {
  const { trainingStore, userStore } = useStores();
  const { user } = userStore;
  const { cancelTraining, setCurrent, training, exercises } = trainingStore;
  const [collapse, setCollapse] = useState({
    technique: false,
    description: false,
    subdivisions: false,
  });
  const changeActiveCollapse = (key: string) => {
    const newValue = !collapse[key];
    setCollapse({ ...collapse, [key]: newValue });
  };
  return (
    <div className="one-train">
      <div className="one-train-content">
        <Swiper
          direction="horizontal"
          indicator={false}
          /*indicator={(total, current) => (
            <div className="customIndicator">{`${current + 1} / ${total}`}</div>
          )}*/
          defaultIndex={training?.currentStep}
          onIndexChange={(index) => {
            setCurrent(index);
          }}
        >
          {exercises.map((el) => (
            <Swiper.Item key={el.id}>
              <Exercise
                user={user}
                collapse={collapse}
                changeActiveCollapse={changeActiveCollapse}
                exercise={el}
              />
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
      <div className="one-train-steps">
        <div>
          <Button
            color="danger"
            block
            onClick={() => {
              cancelTraining();
              setClose(true);
            }}
          >
            Завершить тренировку
          </Button>
        </div>
        <Steps
          style={{
            "--icon-size": "16px",
            padding: 0,
          }}
          current={training?.currentStep}
        >
          {exercises.map((el, i) => (
            <Step
              key={el.id}
              icon={
                i !== training?.currentStep ? (
                  <CheckCircleFill />
                ) : (
                  <ClockCircleFill />
                )
              }
            />
          ))}
        </Steps>
      </div>
    </div>
  );
});
export default TrainingPage;
