import { makeAutoObservable } from "mobx";
import axios from "axios";
import { ApiUrls } from "../queries";
import { IMuscle } from "@strapiTypes/generated/IMuscle";
import { Payload } from "@strapiTypes/generated/Payload";
import qs from "qs";

class MuscleStore {
  muscle: IMuscle;
  muscles: IMuscle[];
  loading: boolean;
  constructor() {
    makeAutoObservable(this);
  }

  getMuscles = async () => {
    try {
      this.setLoading(true);
      const res = await axios.get<Payload<IMuscle[]>>(
        `${ApiUrls.muscles}?${qs.stringify({
          populate: "*",
        })}`,
      );
      if (res.status === 200 && res.data) this.setMuscles(res.data.data);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
  setMuscle = (muscle: IMuscle) => {
    this.muscle = muscle;
  };
  setMuscles = (muscles: IMuscle[]) => {
    this.muscles = muscles;
  };

  getMuscle = async (ID: number | string) => {
    try {
      this.setLoading(true);
      const res = await axios.get<Payload<IMuscle>>(
        `${ApiUrls.musclesLib}/${ID}`,
      );
      if (res.status === 200 && res.data) this.setMuscle(res.data.data);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
}

const muscleStore = new MuscleStore();
export type TMuscleStore = MuscleStore;
export { muscleStore };
