import { makeAutoObservable } from "mobx";
import axios from "axios";

import { ApiUrls } from "../queries";
import { ITraining } from "@strapiTypes/generated/ITraining";
import { Payload } from "@strapiTypes/generated/Payload";
import { IGeneratedTraining } from "@strapiTypes/IGeneratedTraining";
import qs from "qs";
import { Session } from "../utils/session";
import { getRandomIntNum } from "../utils/utils";
import { IExercise } from "@strapiTypes/generated/IExercise";
import { CascaderValue } from "antd-mobile/es/components/cascader-view/cascader-view";

export class TrainingStore {
  trainings: ITraining[];
  training: IGeneratedTraining;
  exercises: IExercise[];
  loading: boolean;
  constructor() {
    makeAutoObservable(this);
  }
  /* Setters */
  setTrainings = (ts: ITraining[]) => {
    this.trainings = ts;
  };
  setTraining = (t: IGeneratedTraining) => {
    Session.set("training", t);
    const exercises = t?.exercises;
    this.setExercises(exercises);
    this.training = t;
  };
  setExercises = (e: IExercise[]) => {
    this.exercises = e;
  };
  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  /* Api */
  getTrainings = async () => {
    try {
      this.setLoading(true);
      const res = await axios.get<Payload<ITraining[]>>(
        `${ApiUrls.trainings}?${qs.stringify({
          populate: "*",
        })}`,
      );
      if (res.status === 200 && res.data) this.setTrainings(res.data.data);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
  getTraining = async (ID: string | number) => {
    const existTraining = Session.get<IGeneratedTraining>("training");
    if (existTraining) {
      this.setTraining(existTraining);
      return;
    }
    try {
      this.setLoading(true);
      const res = await axios.get<Payload<IGeneratedTraining>>(
        `${ApiUrls.generateTraining}/${ID}`,
      );
      if (res.status !== 200 && !res.data) return;
      this.setTraining({ ...res.data.data, currentStep: 0 });
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
  deleteExerciseFromTraining = (ID: number): void | false => {
    if (this.exercises.length < 5) return false;
    const index = this.training.exercises.findIndex((el) => el.id === ID);
    if (index !== -1) this.training.exercises.splice(index, 1);
    this.setTraining(this.training);
  };
  getRandomTrainingID = async () => {
    try {
      this.setLoading(true);
      const trainingsRes = await axios.get<Payload<ITraining[]>>(
        `${ApiUrls.trainings}?${qs.stringify({
          fields: ["id"],
        })}`,
      );
      const trainings = trainingsRes.data.data;
      return trainings[getRandomIntNum(0, trainings.length - 1)].id;
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };

  userChoiceGen = async (IDs: CascaderValue[]) => {
    this.setLoading(true);
    try {
      const res = await axios.post<Payload<IExercise[]>>(
        `${ApiUrls.userChoice}`,
        {
          IDs,
        },
      );
      const data = res.data.data;
      if (data.length) {
        this.setTraining({ ID: 0, exercises: data, currentStep: 0 });
        console.log(data);
        this.setExercises(data);
      }
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };

  /* Navigation train process */
  nextStep = () => {
    this.setCurrent(this.training.currentStep + 1);
  };
  setCurrent = (current: number) => {
    this.setTraining({ ...this.training, currentStep: current });
  };
  prevStep = () => {
    this.setCurrent(this.training.currentStep - 1);
  };
  cancelTraining = () => {
    Session.rm("training");
    this.setTraining(null);
  };
}
const trainingStore = new TrainingStore();
export type TTrainingStore = TrainingStore;
export { trainingStore };
