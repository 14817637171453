import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Loader from "../../../components/loader/Loader";
import { isNumber, noPhotoUrl } from "../../../utils/utils";
import { exerciseStore } from "../../../store/exerciseStore";
import { Paths } from "../../Navigation";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Collapse, Divider, Grid, Image, List } from "antd-mobile";

const Exercise = observer(() => {
  const { ID } = useParams();
  const { loading, exercise, getExercise } = exerciseStore;
  const navigate = useNavigate();
  useEffect(() => {
    if (isNumber(ID)) void getExercise(ID);
  }, [ID, getExercise]);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : exercise ? (
        <Card className="library-single-content">
          <Image src={noPhotoUrl} />
          <Divider contentPosition="left">Название</Divider>
          <Card>{exercise.name}</Card>
          <Divider contentPosition="left">Описание</Divider>
          <Card>{exercise.description}</Card>
          <Divider contentPosition="left">
            Повторения / подходы (рекомендуемо)
          </Divider>
          <Card>
            <Grid columns={2} gap={8}>
              <Grid.Item>
                <div>{exercise.repeatQuant}</div>
              </Grid.Item>
              <Grid.Item>
                <div>{exercise.approachesQuant}</div>
              </Grid.Item>
            </Grid>
          </Card>
          <Divider contentPosition="left">Техника выполнения</Divider>
          <Card>{exercise.technique}</Card>
          <Collapse>
            {!!exercise.workload_muscles.length && (
              <Collapse.Panel key={"Группы"} title={"Группы мышц"}>
                <List>
                  {exercise.workload_muscles
                    .filter((wl) => wl.muscle)
                    .map((el) => (
                      <List.Item
                        onClick={() =>
                          navigate(
                            `/${Paths.library}/${Paths.muscles}/${el.muscle.id}`,
                          )
                        }
                        prefix={
                          <Image
                            src={el.muscle.photo?.url || noPhotoUrl}
                            style={{ borderRadius: 20 }}
                            fit="cover"
                            width={40}
                            height={40}
                          />
                        }
                        key={el.muscle.id}
                      >
                        {el.muscle.name}
                      </List.Item>
                    ))}
                </List>
              </Collapse.Panel>
            )}
            {!!exercise.workload_subdivisions.length && (
              <Collapse.Panel key={"Мышцы"} title={"Мышцы"}>
                <List>
                  {exercise.workload_subdivisions
                    .filter((wl) => wl.subdivision)
                    .map((el) => (
                      <List.Item
                        onClick={() =>
                          navigate(
                            `/${Paths.library}/${Paths.subdivisions}/${el.subdivision.id}`,
                          )
                        }
                        prefix={
                          <Image
                            src={el.subdivision.photo?.url || noPhotoUrl}
                            style={{ borderRadius: 20 }}
                            fit="cover"
                            width={40}
                            height={40}
                          />
                        }
                        key={el.subdivision.id}
                      >
                        {el.subdivision.name}
                      </List.Item>
                    ))}
                </List>
              </Collapse.Panel>
            )}
          </Collapse>
        </Card>
      ) : (
        <div />
      )}
    </div>
  );
});
export default Exercise;
