import { TUserStore, userStore } from "./userStore";
import { muscleStore, TMuscleStore } from "./muscleStore";
import { subdivisionStore, TSubdivisionStore } from "./subdivisionStore";
import { exerciseStore, TExerciseStore } from "./exerciseStore";
import { trainingStore, TTrainingStore } from "./trainingStore";
import { feedbackStore, TFeedBackStore } from "./feedbackStore";

class RootStore {
  userStore: TUserStore;
  muscleStore: TMuscleStore;
  subdivisionStore: TSubdivisionStore;
  exerciseStore: TExerciseStore;
  trainingStore: TTrainingStore;
  feedbackStore: TFeedBackStore;
  constructor() {
    this.userStore = userStore;
    this.muscleStore = muscleStore;
    this.subdivisionStore = subdivisionStore;
    this.exerciseStore = exerciseStore;
    this.trainingStore = trainingStore;
    this.feedbackStore = new feedbackStore(userStore);
  }
}

export default RootStore;
