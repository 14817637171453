import React, { useEffect } from "react";
import { Card, Divider } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useStores } from "../store/RootStoreContext";

const HelloPage = observer(() => {
  const { userStore } = useStores();
  const { guestEnter } = userStore;
  useEffect(() => {
    guestEnter();
  }, []);
  return (
    <div style={{ width: "auto", margin: "12px" }}>
      <h1 style={{ width: "100%", textAlign: "center" }}>
        Добро пожаловать в бота - "Персональный тренер"
      </h1>
      <Card>
        <Divider contentPosition="left">Описание</Divider>
        <p>
          Здесь вы можете изучить разновидности профессиональных названий и
          описаний мышц, упражнений и тренажёров
        </p>
        <p>
          А также вы можете сгенерировать себе тренировку, которая по
          специальным формулам подберёт для Вас уникальный набор упражнений
        </p>
      </Card>
    </div>
  );
});
export default HelloPage;
