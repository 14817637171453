import { IExercise } from "@strapiTypes/generated/IExercise";
import { useNavigate } from "react-router-dom";
import { Card, Collapse, Divider, Grid, Image, List } from "antd-mobile";
import { noPhotoUrl } from "../../../utils/utils";
import React from "react";
import { IUserCustom } from "../../../store/userStore";

interface ITrainExerciseProps {
  user: IUserCustom;
  exercise: IExercise;
  collapse: object;
  changeActiveCollapse: (key: string) => void;
}

/*
todo:
 1) Сохранять текущий шаг в localStorage
 2) Задавать вопрос "Завершить тренировку" если меню переключено
 3) Отображение информации нормальное сделать
 */
const Exercise = ({
  user,
  exercise,
  collapse,
  changeActiveCollapse,
}: ITrainExerciseProps) => {
  const navigate = useNavigate();
  const collapseActiveKeys = Object.entries(collapse)
    .filter(([, val]) => val)
    .map((el) => el[0]);
  return (
    <Card className="train-exercise-content">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Image src={noPhotoUrl} width="50%" />{" "}
      </div>
      <h2 style={{ textAlign: "center" }}>{exercise.name}</h2>
      <Divider contentPosition="left">
        Повторения / подходы (рекомендуемо)
      </Divider>
      <Card>
        <Grid columns={2} gap={8}>
          <Grid.Item>
            <div>{exercise.repeatQuant}</div>
          </Grid.Item>
          <Grid.Item>
            <div>{exercise.approachesQuant}</div>
          </Grid.Item>
        </Grid>
      </Card>
      <Collapse activeKey={collapseActiveKeys}>
        <Collapse.Panel
          onClick={() => {
            changeActiveCollapse("technique");
          }}
          key="technique"
          title="Техника"
        >
          {exercise.technique || "Скоро будет"}
        </Collapse.Panel>
        {!user?.appSettings?.view?.training?.descriptionHide && (
          <Collapse.Panel
            onClick={() => {
              changeActiveCollapse("description");
            }}
            key="description"
            title="Описание"
          >
            {exercise.description}
          </Collapse.Panel>
        )}
        {!user?.appSettings?.view?.training?.muscleHide && (
          <Collapse.Panel
            onClick={() => {
              changeActiveCollapse("subdivisions");
            }}
            key="subdivisions"
            title="Мышцы"
          >
            <List>
              {exercise.workload_subdivisions?.map((el) => (
                <List.Item
                  key={el.subdivision.id}
                  onClick={() =>
                    navigate(
                      `/library/subdivisions/${el.subdivision.id.toString()}`,
                    )
                  }
                  prefix={
                    <Image
                      src={el.subdivision.photo?.url || noPhotoUrl}
                      style={{ borderRadius: 20 }}
                      fit="cover"
                      width={40}
                      height={40}
                    />
                  }
                >
                  {el.subdivision.name}
                </List.Item>
              ))}
            </List>
          </Collapse.Panel>
        )}
      </Collapse>
    </Card>
  );
};
export default Exercise;
