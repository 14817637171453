import { observer } from "mobx-react-lite";
import { Button, Form, ImageUploader, Modal, TextArea } from "antd-mobile";
import { useStores } from "../../store/RootStoreContext";
import { useEffect, useRef, useState } from "react";
import { FormInstance } from "antd-mobile/es/components/form";
import { Navigate } from "react-router-dom";
import { Paths } from "../Navigation";

const Feedback = observer(() => {
  const {
    feedbackStore: { uploadFeedbackImg, submit, result, clearResult, loading },
  } = useStores();
  const ref = useRef<FormInstance>();
  const [send, setSend] = useState<boolean>();
  useEffect(() => {
    if (result) {
      Modal.confirm({
        content: result?.txt,
        confirmText: "ОК",
        cancelText: "",
      }).then(() => {
        if (result.status) {
          setSend(true);
          clearResult();
        }
      });
    }
  }, [result]);
  if (send) return <Navigate to={`/${Paths.profile}`} />;
  return (
    <Form mode="card" ref={ref} onFinish={submit} disabled={loading}>
      <Form.Header>Оставить отзыв</Form.Header>
      <Form.Item
        name="description"
        rules={[{ required: true, message: "Заполните отзыв" }]}
      >
        <TextArea placeholder="Отзыв" rows={7} />
      </Form.Item>
      <Form.Item>
        <ImageUploader
          accept="image/jpeg;image/jpg/image/png"
          upload={uploadFeedbackImg}
          maxCount={6}
          preview={true}
          multiple
        ></ImageUploader>
      </Form.Item>
      <div style={{ padding: 10 }}>
        <Button
          size="large"
          color="primary"
          type="submit"
          block
          loading={loading}
        >
          Отправить
        </Button>
      </div>
    </Form>
  );
});

export default Feedback;
