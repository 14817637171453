import { makeAutoObservable } from "mobx";
import axios from "axios";
import { ApiUrls } from "../queries";
import { IExercise } from "@strapiTypes/generated//IExercise";
import { Payload } from "@strapiTypes/generated/Payload";
import qs from "qs";

class ExerciseStore {
  loading: boolean;
  exercise: IExercise;
  exercisesPayload: Payload<IExercise[]>;
  constructor() {
    makeAutoObservable(this);
  }
  setExercise = (e: IExercise) => {
    this.exercise = e;
  };
  setExercises = (e: Payload<IExercise[]>) => {
    this.exercisesPayload = e;
  };
  getExercise = async (ID: number | string) => {
    try {
      this.setLoading(true);
      const res = await axios.get<Payload<IExercise>>(
        `${ApiUrls.exercisesLib}/${ID}`,
      );
      if (res.status === 200 && res.data) this.setExercise(res.data.data);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
  getExercises = async (page?: number | `${number}`, pageSize?: number) => {
    pageSize ??= +process.env.REACT_APP_PAGINATION;
    try {
      this.setLoading(true);
      const objQ = {
        populate: "*",
      };
      if (page) objQ["pagination"] = { pageSize, page };
      const res = await axios.get<Payload<IExercise[]>>(
        `${ApiUrls.exercises}?${qs.stringify(objQ)}`,
      );
      if (res.status === 200 && res.data) this.setExercises(res.data);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
  getExercisesForLib = async (
    page?: number | `${number}`,
    pageSize?: number,
  ): Promise<Payload<IExercise[]>> => {
    pageSize ??= +process.env.REACT_APP_PAGINATION;
    try {
      this.setLoading(true);
      const objQ = {
        populate: "*",
      };
      if (page) objQ["pagination"] = { pageSize, page };
      const res = await axios.get<Payload<IExercise[]>>(
        `${ApiUrls.exercises}?${qs.stringify(objQ)}`,
      );
      if (res.status === 200 && res.data) return res.data;
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
}

const exerciseStore = new ExerciseStore();
export type TExerciseStore = ExerciseStore;
export { exerciseStore };
