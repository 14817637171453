import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { isNumber, noPhotoUrl } from "../../../utils/utils";
import { Paths } from "../../Navigation";
import { useStores } from "../../../store/RootStoreContext";
import { Card, Collapse, Divider, Image, List } from "antd-mobile";

const Muscle = observer(() => {
  const { ID } = useParams();
  const { muscleStore } = useStores();
  const navigate = useNavigate();
  const { loading, muscle, getMuscle } = muscleStore;
  useEffect(() => {
    if (isNumber(ID)) void getMuscle(ID);
  }, [ID, getMuscle]);
  return loading ? (
    <Loader />
  ) : muscle ? (
    <Card className="library-single-content">
      <Image src={noPhotoUrl} />
      <Divider contentPosition="left">Название</Divider>
      <Card>{muscle.name}</Card>
      <Divider contentPosition="left">Функции</Divider>
      <Card>{muscle.function}</Card>
      <Divider contentPosition="left">Описание</Divider>
      <Card>{muscle.description}</Card>
      <Collapse>
        {muscle.subdivisions.length && (
          <Collapse.Panel
            key={"Составляющие мышцы"}
            title={"Составляющие мышцы"}
          >
            <List>
              {muscle.subdivisions.map((el) => (
                <List.Item
                  onClick={() =>
                    navigate(`/${Paths.library}/${Paths.subdivisions}/${el.id}`)
                  }
                  prefix={
                    <Image
                      src={el.photo?.url || noPhotoUrl}
                      style={{ borderRadius: 20 }}
                      fit="cover"
                      width={40}
                      height={40}
                    />
                  }
                  key={el.id}
                >
                  {el.name}
                </List.Item>
              ))}
            </List>
          </Collapse.Panel>
        )}
        {muscle.workload_muscles.length && (
          <Collapse.Panel key={"Упражнения"} title={"Упражнения"}>
            <List>
              {muscle.workload_muscles
                .filter((wl) => wl.exercise)
                .map((el) => (
                  <List.Item
                    onClick={() =>
                      navigate(
                        `/${Paths.library}/${Paths.exercises}/${el.exercise.id}`,
                      )
                    }
                    prefix={
                      <Image
                        src={el.exercise.photo?.url || noPhotoUrl}
                        style={{ borderRadius: 20 }}
                        fit="cover"
                        width={40}
                        height={40}
                      />
                    }
                    key={el.exercise.id}
                  >
                    {el.exercise.name}
                  </List.Item>
                ))}
            </List>
          </Collapse.Panel>
        )}
      </Collapse>
    </Card>
  ) : (
    <div />
  );
});
export default Muscle;
