import { observer } from "mobx-react-lite";
import { useStores } from "../../store/RootStoreContext";
import React, { useCallback, useEffect, useState } from "react";
import { Session } from "../../utils/session";
import { IGeneratedTraining } from "@strapiTypes/IGeneratedTraining";
import { Navigate, useNavigate } from "react-router-dom";
import {
  AutoCenter,
  Button,
  ButtonProps,
  CascaderView,
  Dialog,
} from "antd-mobile";
import { Paths } from "../Navigation";
import {
  CascaderOption,
  CascaderValue,
} from "antd-mobile/es/components/cascader-view/cascader-view";

/*todo:
   1) Доделать выбор мышц и сложности (сейчас в state, а должно в training
   2) Придумать правильную обработку и отправку настройки тренировки пользователем
   3) Связать со вторым экраном (во втором экране сделать Tab)*/
const Trainings = observer(() => {
  const training = Session.get<IGeneratedTraining>("training");
  if (training) return <Navigate to={training.ID.toString()} />;
  const navigate = useNavigate();
  const { muscleStore, trainingStore } = useStores();
  const { muscles, getMuscles } = muscleStore;
  const { getRandomTrainingID, userChoiceGen } = trainingStore;
  const [columns, setColumns] = useState<CascaderOption[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getMuscles().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (muscles?.length) {
      const columns: CascaderOption[] = [];
      muscles.forEach((m) => {
        columns.push({
          value: m.id.toString(),
          label: m.name,
          children: muscles.map((el) => ({
            value: el.id.toString(),
            label: el.name,
          })),
        });
      });
      setColumns(columns);
    }
  }, [muscles?.length]);

  const randomTraining = useCallback(async () => {
    const ID = await getRandomTrainingID();
    navigate(`/${Paths.trainings}/${ID}`);
  }, []);
  const [btnColor, setBtnColor] = useState<ButtonProps["color"]>("default");
  const [userChoice, setUserChoice] = useState<CascaderValue[]>([]);
  const generate = async () => {
    if (userChoice?.length !== 2) {
      await Dialog.alert({
        content: "Выберите мышцы",
        confirmText: "ОК",
      });
      return;
    }
    await userChoiceGen(userChoice);
    navigate(`/${Paths.training}`);
  };
  return (
    <div>
      <div style={{ margin: 12, textAlign: "center" }}>
        {/*<h1>Какую тренировку для Вас сформировать сегодня?</h1>
        <h4>Отметьте настройки и мы автоматически сформируем Вам тренировку</h4>*/}
      </div>
      <Button onClick={randomTraining} block size="large" color="primary">
        Сформировать случайно
      </Button>
      <CascaderView
        options={columns}
        placeholder={"Мышца"}
        value={userChoice}
        onChange={(v) => {
          setBtnColor(v.length === 2 ? "primary" : "default");
          setUserChoice(v);
        }}
        loading={loading}
        style={{ "--height": "500px" }}
      />
      <AutoCenter>
        <Button color={btnColor} onClick={generate}>
          Сформировать
        </Button>
      </AutoCenter>
    </div>
  );
});

export default Trainings;
