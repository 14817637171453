type SessionKeys = "training" | string;

export class Session {
  /**
   * Check sessionStorage has key
   * @param key Ключ
   */
  static has(key: SessionKeys) {
    return !!sessionStorage.getItem(key);
  }
  /**
   * Set item from sessionStorage
   * @param key Ключ
   * @param value Значение
   */
  static set(key: SessionKeys, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  /**
   * Get item from sessionStorage
   * @param key Ключ
   * @param parse применяет JSON.parse на значение
   */
  static get<T>(key: SessionKeys, parse = true): T {
    const val = sessionStorage.getItem(key);
    return parse ? JSON.parse(val) : val;
  }
  /**
   * Remove item from sessionStorage
   * @param key Ключ
   */
  static rm(key: SessionKeys) {
    sessionStorage.removeItem(key);
  }
}
