import { observer } from "mobx-react-lite";
import {
  AutoCenter,
  Button,
  Card,
  Checkbox,
  Collapse,
  Dialog,
  Divider,
  Grid,
  Image,
  List,
} from "antd-mobile";
import { noPhotoUser } from "../../utils/utils";
import { useStores } from "../../store/RootStoreContext";
import { CheckOutline, CloseOutline } from "antd-mobile-icons";
import { Paths } from "forms/Navigation";
import { useNavigate } from "react-router-dom";
import { IAppSettings } from "@strapiTypes/IUserSettings";

const Profile = observer(() => {
  const { userStore } = useStores();
  const { logOut, user, isAuth, updateUserSettings } = userStore;
  const usr = window.Telegram.WebApp.initDataUnsafe.user;
  const navigate = useNavigate();
  const updateSett = async (name, value: boolean) => {
    const prevData: IAppSettings["view"]["training"] =
      user.appSettings?.view?.training ?? {};
    await updateUserSettings({
      view: {
        training: {
          ...prevData,
          [name]: value,
        },
      },
    });
  };
  return (
    <>
      <Divider>Личный кабинет</Divider>
      <Card style={{ margin: 12 }}>
        <Image width="100" height="100" src={noPhotoUser} />
        <Divider contentPosition="left">Имя пользователя</Divider>
        <Card>
          <Grid columns={2} gap={8}>
            <Grid.Item>
              {user?.username ?? usr?.username ?? "Неизвестный"}
            </Grid.Item>
            <Grid.Item>
              Авторизован:&nbsp;
              {isAuth ? (
                <CheckOutline color="green" />
              ) : (
                <CloseOutline color="red" />
              )}
            </Grid.Item>
          </Grid>
        </Card>
        <Divider contentPosition="left">Настройки бота</Divider>
        {user ? (
          <Collapse>
            <Collapse.Panel key="settings" title="Настройки отображения">
              <List>
                <List.Item>
                  <Checkbox
                    onChange={async (v) => {
                      await updateSett("settingsHide", v);
                    }}
                    checked={user.appSettings?.view?.training?.settingsHide}
                  >
                    Скрыть раздел "Настройка" в тренировке
                  </Checkbox>
                </List.Item>
                <List.Item>
                  <Checkbox
                    onChange={async (v) => {
                      await updateSett("descriptionHide", v);
                    }}
                    checked={user.appSettings?.view?.training?.descriptionHide}
                  >
                    Скрыть раздел "Описание" в тренировке
                  </Checkbox>
                </List.Item>
                <List.Item>
                  <Checkbox
                    onChange={async (v) => {
                      await updateSett("muscleHide", v);
                    }}
                    checked={user.appSettings?.view?.training?.muscleHide}
                  >
                    Скрыть раздел "Мышцы" в тренировке
                  </Checkbox>
                </List.Item>
              </List>
            </Collapse.Panel>
          </Collapse>
        ) : (
          <Card>
            <Grid.Item>Для настроек необходимо авторизоваться</Grid.Item>
          </Card>
        )}
        <AutoCenter style={{ marginTop: 30 }}>
          <Button
            color="primary"
            onClick={() => navigate(`/${Paths.feedback}`)}
          >
            Оставить отзыв
          </Button>
        </AutoCenter>
        <AutoCenter style={{ marginTop: 30 }}>
          {isAuth ? (
            <Button
              color="danger"
              onClick={async () => {
                const res = await Dialog.confirm({
                  content: "Выйти из профиля?",
                  confirmText: "Да",
                  cancelText: "Нет",
                });
                if (res) logOut();
              }}
            >
              Выйти из профиля
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => {
                navigate(`/${Paths.login}`);
              }}
            >
              Войти
            </Button>
          )}
        </AutoCenter>
      </Card>
    </>
  );
});

// export default authorized(Profile);
export default Profile;
