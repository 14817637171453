import { createContext, useContext } from "react";
import RootStore from "./rootStore";

export const RootStoreContext = createContext<RootStore | null>(null);

export const useStores = (): RootStore => {
  const context = useContext(RootStoreContext);
  if (context === null) {
    throw new Error("Не обернуто в Provider");
  }
  return context;
};
