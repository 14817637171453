import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export enum ApiUrls {
  subdivisions = "subdivisions",
  subdivisionsLib = "subdivisionsLib",
  muscles = "muscles",
  musclesLib = "musclesLib",
  exercises = "exercises",
  exercisesLib = "exercisesLib",
  trainings = "trainings",
  generateTraining = "training-gen",
  trainExercises = "train-exercises",
  user = "user",
  userChoice = "userChoice",
}
