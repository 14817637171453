import { makeAutoObservable } from "mobx";
import axios, { AxiosError } from "axios";
import { IUserSettings } from "@strapiTypes/IUserSettings";
import { IUser } from "@strapiTypes/generated/IUser";
import { Payload } from "@strapiTypes/generated/Payload";

/*
todo: нужно сделать регистрацию для пользователей в WEB приложении
 для пользователей в tg-приложении нужно автоматически создавать пользователя

todo: для чего и что нужно сделать:
 + хранение информации о тренировках пользователя
 + настройка аккаунта (что показывать не показывать в упражнениях (мб какие-то упражнения отсутствуют в зале)
 + ведение посещения (сколько раз зашёл в бота, как часто пользуется, для аналитики)
 + для отзывов, чтобы люди могли писать отзывы
 */
export interface ILoginData {
  identifier: string;
  password: string;
  rememberMe?: boolean;
}

export interface IRegData {
  username: string;
  email: string;
  password: string;
}

export interface IAuthenticatedUser {
  jwt: string;
  user: IUserCustom;
}
const defaultAppSettings: IUserSettings["appSettings"] = {
  view: {
    training: {
      settingsHide: false,
      descriptionHide: false,
      muscleHide: false,
    },
  },
};
export interface IUserCustom extends IUser {
  appSettings: IUserSettings["appSettings"];
  settingID: IUserSettings["id"];
}
class UserStore {
  user: IUserCustom;
  userTG = window.Telegram.WebApp.initDataUnsafe.user;
  loading: boolean = false;
  isAuth: boolean = false;
  constructor() {
    makeAutoObservable(this);
    const user = localStorage.getItem("user");
    const jwt = localStorage.getItem("token");
    if (user && jwt) this.setUserAndJwt({ user: JSON.parse(user), jwt });
  }
  setAuth = (isAuth: boolean) => {
    this.isAuth = isAuth;
  };
  setUser = (user: IUserCustom) => {
    if (user) localStorage.setItem("user", JSON.stringify(user));
    else localStorage.removeItem("user");
    this.user = user;
    this.setAuth(true);
  };
  setUserAndJwt = (authUser: IAuthenticatedUser) => {
    this.setUser(authUser.user);
    this.setJwt(authUser.jwt);
  };
  logOut = () => {
    this.setUser(null);
    this.setJwt(null);
    axios.defaults.headers.common["Authorization"] = null;
  };
  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
  getUserSettings = async (loading = true): Promise<IUserSettings> => {
    if (!this.user.id) return;
    if (loading) this.setLoading(true);
    try {
      const res = await axios.get<Payload<IUserSettings>>(
        `${process.env.REACT_APP_BASE_URL}/user-settings/${this.user.id}`,
      );

      if (res.data) return res.data.data;
    } catch (e) {
      if (e.response.status === 404) return await this.createUserSettings();
    } finally {
      if (loading) this.setLoading(false);
    }
  };
  createUserSettings = async () => {
    try {
      const res = await axios.post<Payload<IUserSettings>>(
        `${process.env.REACT_APP_BASE_URL}/user-settings`,
        { appSettings: defaultAppSettings },
      );
      if (res.data) return res.data.data;
    } catch (e) {
    } finally {
    }
  };
  updateUserSettings = async (appSettings: IUserSettings["appSettings"]) => {
    if (!this.user.id) return;
    try {
      const res = await axios.put<Payload<IUserSettings>>(
        `${process.env.REACT_APP_BASE_URL}/user-settings/${this.user.settingID}`,
        { appSettings },
      );
      if (res.data) {
        this.setUser({
          ...this.user,
          settingID: res.data.data.id,
          appSettings: res.data.data.appSettings,
        });
      }
    } catch (e) {
    } finally {
    }
  };
  guestEnter = async () => {
    try {
      await axios.post<Payload<IUserSettings>>(
        `${process.env.REACT_APP_BASE_URL}/session/guestEnter`,
        this.userTG,
      );
    } catch (e) {
    } finally {
    }
  };
  login = async (authData: ILoginData) => {
    this.setLoading(true);
    try {
      const res = await axios.post<IAuthenticatedUser>(
        `${process.env.REACT_APP_BASE_URL}/auth/local`,
        authData,
        { withCredentials: true },
      );
      if (res.data) {
        this.setUserAndJwt(res.data);
        const userSettings = await this.getUserSettings();
        if (userSettings)
          this.setUser({
            ...this.user,
            settingID: userSettings.id,
            appSettings: userSettings.appSettings,
          });
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response.status === 500)
        throw Error("Не удалось залогиниться");
    } finally {
      this.setLoading(false);
    }
  };
  setJwt = (jwt: string) => {
    if (!jwt) {
      localStorage.removeItem("token");
      axios.defaults.headers.common["Authorization"] = null;
      this.setAuth(false);
      return;
    }
    let jwtToken = localStorage.getItem("token");
    if (!jwtToken) {
      jwtToken = jwt;
      localStorage.setItem("token", jwt);
    }
    this.setAuth(true);
    axios.defaults.headers.common["Authorization"] = `bearer ${jwtToken}`;
  };
  register = async (regData: IRegData) => {
    try {
      const res = await axios.post<IAuthenticatedUser>(
        `${process.env.REACT_APP_BASE_URL}/auth/local/register`,
        regData,
      );
      if (res.data) {
        this.setUserAndJwt(res.data);
        const userSettings = await this.getUserSettings();
        if (userSettings)
          this.setUser({
            ...this.user,
            settingID: userSettings.id,
            appSettings: userSettings.appSettings,
          });
      }
    } catch (e) {
    } finally {
    }
  };
}

const userStore = new UserStore();
export type TUserStore = UserStore;
export { userStore };
