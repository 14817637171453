import { Link } from "react-router-dom";
import React from "react";
import "./NotFoundPage.css";

const NotFoundPage = () => (
  <div className="pageNotFound">
    <h2>Страница не найдена</h2>
    <Link to={`/`} className="block accent">
      Вернуться на главый экран
    </Link>
  </div>
);

export default NotFoundPage;
