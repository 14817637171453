import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { TabBar } from "antd-mobile";
import { Paths } from "../Navigation";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ExclamationCircleOutline,
  ExclamationShieldOutline,
  ExclamationTriangleOutline,
} from "antd-mobile-icons";

const Library = observer(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tabs = [
    {
      key: `${Paths.muscles}`,
      title: "Группы",
      icon: <ExclamationCircleOutline />,
    },
    {
      key: `${Paths.subdivisions}`,
      title: "Мышцы",
      icon: <ExclamationShieldOutline />,
    },
    {
      key: `${Paths.exercises}`,
      title: "Упражнения",
      icon: <ExclamationTriangleOutline />,
    },
  ];
  const activeKey = pathname.split("/")[2];
  useEffect(() => {
    if (!activeKey) navigate(Paths.muscles);
  }, []);

  return (
    <>
      <div className="library-menu">
        <TabBar activeKey={activeKey} onChange={navigate}>
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
      <div className="library-content">
        <Outlet />
      </div>
    </>
  );
});

export default Library;
